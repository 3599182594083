import React from 'react';
import * as T from 'prop-types';
import { Button } from 'state-template';

const PanelButton = ({
  text, variant, onClick, isOpen, ...rest
}) => {
  const cn = isOpen ? 'PanelButton PanelButton--active' : 'PanelButton';

  return (
    <Button
      text={text}
      variant={variant}
      onClick={onClick}
      iconProps={{ name: isOpen ? 'caret-up' : 'caret-down' }}
      className={cn}
      {...rest}
    />
  );
};

PanelButton.propTypes = {
  text: T.string.isRequired,
  variant: T.string,
  onClick: T.func.isRequired,
  isOpen: T.bool.isRequired,
};

PanelButton.defaultProps = {
  variant: 'default',
};

export default PanelButton;
